import { useModal } from '@/hooks';
import { Button, Container, H1, Paragraph, YStack } from '@holo/ui';
import Image from 'next/image';
import fofSection1 from 'public/img/fof-section1.png';

const Section1 = () => {
  const { open } = useModal('join-pilot-program');

  return (
    <YStack borderBottomWidth={1} borderColor="$border" py={8 * 10} $md={{ py: 8 * 20 }}>
      <Container>
        <YStack tag="section" gap="$8" $md={{ flexDirection: 'row', alignItems: 'center' }}>
          <YStack
            ai="center"
            mx="auto"
            maxWidth={8 * 60}
            flex={1}
            $md={{ flexBasis: 0, maxWidth: 'none', mx: 0, ai: 'flex-start' }}
          >
            <H1
              fontSize="$42"
              textAlign="center"
              $xs={{ fontSize: '$52' }}
              $md={{ fontSize: '$72', textAlign: 'left' }}
            >
              The future of farming is powered by AI
            </H1>

            <Paragraph lineHeight="$2" textAlign="center" mt="$8" $md={{ textAlign: 'left' }}>
              We are building the next generation farm management system that is integrating support for all your
              planning, production and sales operations, including automatic reporting for FSMA, GAP, and Organic
              certification. Join our pilot program to get early access.
            </Paragraph>

            <Button mt="$10" onPress={open}>
              Join our pilot program
            </Button>
          </YStack>

          <YStack mt="$4" flex={1} $md={{ flexBasis: 0, mt: '$0' }}>
            <Image
              src={fofSection1}
              priority
              loading="eager"
              alt="Graphic of crop planning"
              style={{
                width: 'auto',
                height: 'auto',
              }}
            />
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default Section1;
