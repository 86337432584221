import AboutHoloBanner from '@/components/AboutHoloBanner/AboutHoloBanner';
import Section1 from '@/components/future-of-farming/Section1';
import Section2 from '@/components/future-of-farming/Section2';
import Section3 from '@/components/future-of-farming/Section3';
import Section4 from '@/components/future-of-farming/Section4';
import Section5 from '@/components/future-of-farming/Section5';
import Section6 from '@/components/future-of-farming/Section6';
import Section7 from '@/components/future-of-farming/Section7';
import Section8 from '@/components/future-of-farming/Section8';
import Section9 from '@/components/future-of-farming/Section9';
import SEO from '@/seo';
import type { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: await serverSideTranslations(locale ?? 'en'),
  };
};

const Index = () => {
  return (
    <>
      <SEO title="The future of agriculture" />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <AboutHoloBanner />
    </>
  );
};

export default Index;
